import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.NEXT_PUBLIC_API_URL ?? "/api";

console.log(`Using API url: ${baseUrl}`);

export const api = createApi({
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: () => ({}),
  tagTypes: ["File", "Image", "Layer", "Project", "Shape", "Tool", "Workspace"],
});
