import { EntityState, PayloadAction, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { ActionTypes, StateWithHistory } from "redux-undo";
import { v4 as uuidv4 } from "uuid";

import {
  WorkspaceSliceState,
  undoableWorkspaceReducer,
  workspaceSliceName,
} from "@/redux/features/editor/workspaceSlice";

import { ProjectId } from "../projects/types";
import { InstrumentName, Tool, Workspace, WorkspaceId } from "./types";

export const workspacesAdapter = createEntityAdapter<Workspace>();

interface EditorSliceState {
  projectId: ProjectId | null;
  workspaces: EntityState<Workspace, WorkspaceId>;
  activeWorkspaceId: WorkspaceId;
}

function getEditorInitialState(): EditorSliceState {
  const workspaceId = `workspace-${uuidv4()}`;
  const history: StateWithHistory<WorkspaceSliceState> = undoableWorkspaceReducer(undefined, {
    type: "INIT_WORKSPACE",
  });

  const workspace: Workspace = {
    id: workspaceId,
    name: "Main Workspace",
    history,
    config: {},
  };

  return {
    projectId: null,
    workspaces: workspacesAdapter.addOne(workspacesAdapter.getInitialState(), workspace),
    activeWorkspaceId: workspaceId,
  };
}

export const editorSlice = createSlice({
  name: "projects",
  initialState: getEditorInitialState(),
  reducers: {
    openedProject(state, action: PayloadAction<{ projectId: ProjectId }>) {
      state.projectId = action.payload.projectId;
    },
    addedWorkspace: {
      reducer(state, action: PayloadAction<{ workspace: Workspace }>) {
        const { workspace } = action.payload;

        state.workspaces = workspacesAdapter.addOne(state.workspaces, workspace);

        state.activeWorkspaceId = workspace.id;
      },
      prepare({ workspace }: { workspace: Omit<Workspace, "id" | "history"> }) {
        const id = `workspace-${uuidv4()}`;
        const history: StateWithHistory<WorkspaceSliceState> = undoableWorkspaceReducer(undefined, {
          type: "INIT_WORKSPACE",
        });
        const payload = { workspace: { id, history, ...workspace } };
        return { payload };
      },
    },
    addedNewWorkspace(state) {
      const workspace: Omit<Workspace, "id" | "history"> = {
        name: "New workspace",
        tool: {
          id: "select",
          config: {},
        },
        config: {},
      };
      editorSlice.caseReducers.addedWorkspace(state, editorSlice.actions.addedWorkspace({ workspace }));
    },
    activatedWorkspace(state, action: PayloadAction<{ workspaceId: WorkspaceId }>) {
      const { workspaceId } = action.payload;

      const workspace = state.workspaces.entities[workspaceId];
      if (!workspace) throw new Error(`Workspace not found: ${workspaceId}`);

      state.activeWorkspaceId = workspaceId;
    },
    activatedWorkspaceTool(state, action: PayloadAction<{ workspaceId: WorkspaceId; tool: Tool }>) {
      const { workspaceId, tool } = action.payload;

      const workspace = state.workspaces.entities[workspaceId];
      if (!workspace) throw new Error(`Workspace not found: ${workspaceId}`);

      workspace.tool = tool;
    },
    instrumentSelected(state, action: PayloadAction<{ workspaceId: WorkspaceId; instrument: InstrumentName }>) {
      const { workspaceId, instrument } = action.payload;
      const workspace = state.workspaces.entities[workspaceId];

      if (!workspace) {
        throw new Error(`Workspace not found: ${workspaceId}`);
      }

      workspace.instrument = instrument;
    },
    removedWorkspace(state, action: PayloadAction<{ workspaceId: WorkspaceId }>) {
      const { workspaceId } = action.payload;

      const workspace = state.workspaces.entities[workspaceId];
      if (!workspace) throw new Error(`Workspace not found: ${workspaceId}`);

      workspacesAdapter.removeOne(state.workspaces, workspaceId);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => {
        return (
          action.type.startsWith(workspaceSliceName) ||
          action.type === ActionTypes.UNDO ||
          action.type === ActionTypes.REDO
        );
      },
      (state, action) => {
        const activeWorkspaceId = state.activeWorkspaceId;
        if (!activeWorkspaceId) throw new Error("No active workspace");
        const activeWorkspace = state.workspaces.entities[activeWorkspaceId];
        if (!activeWorkspace) throw new Error(`Workspace not found: ${activeWorkspaceId}`);
        const history = activeWorkspace.history as StateWithHistory<WorkspaceSliceState>;
        activeWorkspace.history = undoableWorkspaceReducer(history, action) as any; // TODO: idk how to avoid WritableDraft problems
      },
    );
  },
});

export const {
  openedProject,
  addedWorkspace,
  addedNewWorkspace,
  activatedWorkspace,
  activatedWorkspaceTool,
  removedWorkspace,
  instrumentSelected,
} = editorSlice.actions;
