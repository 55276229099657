import { EntityState, PayloadAction, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import undoable from "redux-undo";
import { v4 as uuidv4 } from "uuid";

import {
  File,
  FileId,
  Image,
  Layer,
  LayerId,
  Phase,
  PhaseId,
  Polygon,
  PolygonId,
  Shape,
  ShapeId,
} from "@/redux/features/editor/types";

function calcPolygonArea(vertices: [number, number][]): number {
  let total = 0;

  for (let i = 0, l = vertices.length; i < l; i++) {
    const addX = vertices[i][0];
    const addY = vertices[i == vertices.length - 1 ? 0 : i + 1][1];
    const subX = vertices[i == vertices.length - 1 ? 0 : i + 1][0];
    const subY = vertices[i][1];

    total += addX * addY * 0.5;
    total -= subX * subY * 0.5;
  }

  return Math.abs(total);
}

export const filesAdapter = createEntityAdapter<File>();
export const shapesAdapter = createEntityAdapter<Shape>();
export const layersAdapter = createEntityAdapter<Layer>();
export const polygonsAdapter = createEntityAdapter<Polygon>();
export const phasesAdapter = createEntityAdapter<Phase>();

export interface WorkspaceSliceState {
  files: EntityState<File, FileId>;
  shapes: EntityState<Shape, ShapeId>;
  layers: EntityState<Layer, LayerId>;
  polygons: EntityState<Polygon, PolygonId>;
  phases: EntityState<Phase, PhaseId>;
  activeLayerId: LayerId;
}

function getWorkspaceInitialState(): WorkspaceSliceState {
  const layerId = `layer-${uuidv4()}`;
  const layer = {
    id: layerId,
    name: "Layer 1",
    visible: true,
    shapes: {
      ids: [],
      selected: [],
    },
  };
  return {
    files: filesAdapter.getInitialState(),
    layers: layersAdapter.addOne(layersAdapter.getInitialState(), layer),
    shapes: shapesAdapter.getInitialState(),
    polygons: polygonsAdapter.getInitialState(),
    phases: phasesAdapter.getInitialState(),
    activeLayerId: layerId,
  };
}

export const workspaceSliceName = "workspace";

export const workspaceSlice = createSlice({
  name: workspaceSliceName,
  initialState: getWorkspaceInitialState(),
  reducers: {
    addedFile: {
      reducer(state, action: PayloadAction<{ file: File }>) {
        const { file } = action.payload;
        filesAdapter.addOne(state.files, file);
      },
      prepare({ file }: { file: Omit<File, "id"> }) {
        const id = `file-${uuidv4()}`;
        const payload = { file: { id, ...file } };
        return { payload };
      },
    },
    removedFile(state, action: PayloadAction<{ fileId: FileId }>) {
      const { fileId } = action.payload;
      filesAdapter.removeOne(state.files, fileId);
    },
    addedImage(
      state,
      action: PayloadAction<{
        layerId: LayerId;
        file: Omit<File, "id">;
        image: Omit<Image, "id" | "fileId">;
      }>,
    ) {
      const { file, image } = action.payload;

      const addedFileAction = workspaceSlice.actions.addedFile({ file });
      workspaceSlice.caseReducers.addedFile(state, addedFileAction);
      const { id: fileId } = addedFileAction.payload.file;

      const shape: Omit<Image, "id"> = {
        ...image,
        fileId,
      };

      workspaceSlice.caseReducers.addedShape(
        state,
        workspaceSlice.actions.addedShape({
          shape: shape,
        }),
      );
    },
    addedNewImage(
      state,
      action: PayloadAction<{
        file: Omit<File, "id">;
      }>,
    ) {
      const { file } = action.payload;
      const addedFileAction = workspaceSlice.actions.addedFile({ file });
      workspaceSlice.caseReducers.addedFile(state, addedFileAction);
      const { id: fileId } = addedFileAction.payload.file;

      const layerId = state.activeLayerId;
      if (!layerId) throw new Error("No active layer");

      const image: Omit<Image, "id"> = {
        fileId,
        type: "image",
        config: { x: 0, y: 0 },
        selected: false,
        layerId: layerId,
        polygons: [],
        embedding: [],
        segmenting: false,
      };

      workspaceSlice.caseReducers.addedShape(
        state,
        workspaceSlice.actions.addedShape({
          shape: image,
        }),
      );
    },
    addedShape: {
      reducer(state, action: PayloadAction<{ shape: Shape }>) {
        const { shape } = action.payload;
        const layer = state.layers.entities[shape.layerId];

        shapesAdapter.addOne(state.shapes, shape);
        layer.shapes.ids.push(shape.id);
      },
      prepare({ shape }: { shape: Omit<Shape, "id"> }) {
        const id = `shape-${uuidv4()}`;
        const payload = { shape: { id, ...shape } as Shape }; // TODO: group doesn't have id
        return { payload };
      },
    },
    arrangeShapes(
      state,
      action: PayloadAction<{
        shapeIds: ShapeId[];
      }>,
    ) {
      // extract layerId from the shape and ensure that all shapes are in the same layer
      const layerId = state.shapes.entities[action.payload.shapeIds[0]].layerId;
      if (action.payload.shapeIds.some((id) => state.shapes.entities[id].layerId !== layerId)) {
        throw new Error("All shapes must be in the same layer");
      }
      // ensure that all shapeIds on the current layer are the same as the provided shapeIds
      const layer = state.layers.entities[layerId];
      if (layer.shapes.ids.length !== action.payload.shapeIds.length) {
        throw new Error("All shapes must be in the same layer");
      }
      if (!layer.shapes.ids.every((id) => action.payload.shapeIds.includes(id))) {
        throw new Error("All shapes must be in the same layer");
      }
      // reorder the shapes
      layer.shapes.ids = action.payload.shapeIds;
    },
    addedPolygon: {
      reducer(state, action: PayloadAction<{ imageId: ShapeId; polygon: Polygon }>) {
        const { imageId, polygon } = action.payload;
        const image = state.shapes.entities[imageId];
        if (image.type !== "image") throw new Error(`Shape ${imageId} is not an image`);

        state.polygons = polygonsAdapter.addOne(state.polygons, polygon);
        if (!image.polygons) image.polygons = [];
        image.polygons.push(polygon.id);
      },
      prepare({ imageId, polygon }: { imageId: ShapeId; polygon: Omit<Polygon, "id"> }) {
        const id = `polygon-${uuidv4()}`;
        const payload = { imageId, polygon: { id, ...polygon } };
        return { payload };
      },
    },
    polygonsAdded: {
      reducer(state, action: PayloadAction<{ imageId: ShapeId; polygons: Polygon[] }>) {
        const { imageId, polygons } = action.payload;
        const image = state.shapes.entities[imageId];

        if (image.type !== "image") {
          throw new Error(`Shape ${imageId} is not an image`);
        }

        state.polygons = polygonsAdapter.addMany(state.polygons, polygons);

        console.debug(`[workspaceSlice]: On selected image added ${polygons.length} new polygons`);

        if (!image.polygons) {
          image.polygons = [];
        }

        image.polygons = [...image.polygons, ...polygons.map(({ id }) => id)];
      },
      prepare({ imageId, polygons }: { imageId: ShapeId; polygons: Omit<Polygon, "id">[] }) {
        const prepared = polygons.map((polygon) => {
          const id = `polygon-${uuidv4()}`;

          return { id, ...polygon };
        });

        const payload = { imageId, polygons: prepared };
        return { payload };
      },
    },
    phaseAdded: {
      reducer(state, action: PayloadAction<{ polygons: Polygon[]; phase: Phase }>) {
        const { polygons, phase } = action.payload;

        state.phases = phasesAdapter.addOne(state.phases, phase);

        const statePolygons = polygonsAdapter.getSelectors().selectAll(state.polygons);

        const minArea = 20;
        /*
        NOTE: сортируем все полигоны по площади, чтобы большие полигоны не перекрывали маленькие.
        в идеале это надо делать при каждом изменении в polygonsAdapter, однако пока интерфейс не оптимизирован,
        это самоубийство.
        также фильтруем полигоны с площадью меньше minArea,
        т.к. без поддержки динамического изменения размеров точек и линий вокруг полигонов работать с ними невозможно.
         */
        const allPolygons = [...statePolygons, ...polygons]
          .map((polygon) => ({
            polygon: polygon,
            area: calcPolygonArea(polygon.points),
          }))
          .filter(({ area }) => area > minArea)
          .sort((p1, p2) => {
            if (p1.area < p2.area) {
              return -1;
            }
            if (p1.area > p2.area) {
              return 1;
            }
            return 0;
          })
          .map(({ polygon }) => polygon)
          .reverse();

        state.polygons = polygonsAdapter.setAll(state.polygons, allPolygons);

        console.debug(`[workspaceSlice]: New phase with ${phase.polygonsIds.size} added (${phase.color})`);
      },
      prepare({
        shapeId,
        polygons,
        color,
        meanGreyIntensity,
      }: {
        polygons: Omit<Polygon, "id">[];
        shapeId: ShapeId;
        color: string;
        meanGreyIntensity: number;
      }) {
        const prepared = polygons.map((polygon) => {
          const id = `polygon-${uuidv4()}`;

          return { id, ...polygon };
        });

        const phaseId = `phase-${uuidv4()}`;

        const phase: Phase = {
          id: phaseId,
          polygonsIds: new Set(prepared.map(({ id }) => id)),
          shapeId,
          isVisible: true,
          shouldBeColored: false,
          color,
          meanGreyIntensity,
        };

        const payload = { polygons: prepared, phase };
        return { payload };
      },
    },
    phaseVisibilityChanged(
      state,
      action: PayloadAction<{
        phaseId: string;
        isVisible: boolean;
      }>,
    ) {
      const { phaseId, isVisible } = action.payload;

      phasesAdapter.updateOne(state.phases, {
        id: phaseId,
        changes: {
          isVisible,
        },
      });

      console.debug(`[workspaceSlice]: phaseVisibilityChanged`);
    },
    phaseColoredChanged(
      state,
      action: PayloadAction<{
        phaseId: string;
        shouldBeColored: boolean;
      }>,
    ) {
      const { phaseId, shouldBeColored } = action.payload;

      phasesAdapter.updateOne(state.phases, {
        id: phaseId,
        changes: {
          shouldBeColored,
        },
      });

      console.debug(`[workspaceSlice]: phaseColoredChanged`);
    },
    updatedPolygon(
      state,
      action: PayloadAction<{
        polygonId: PolygonId;
        changes: Partial<Polygon>;
      }>,
    ) {
      const { polygonId, changes } = action.payload;
      state.polygons = polygonsAdapter.updateOne(state.polygons, { id: polygonId, changes });
    },
    removedPolygon(state, action: PayloadAction<{ polygonId: PolygonId }>) {
      const { polygonId } = action.payload;
      const polygon = state.polygons.entities[polygonId];
      const image = state.shapes.entities[polygon.shapeId];

      if (image.type !== "image") {
        throw new Error(`Shape ${polygon.shapeId} is not an image`);
      }

      image.polygons = image.polygons?.filter((id) => id !== polygonId) ?? [];
      state.polygons = polygonsAdapter.removeOne(state.polygons, polygonId);
    },
    removeAllPolygonsFromImage(state, action: PayloadAction<{ imageId: ShapeId }>) {
      const imageId = action.payload.imageId;
      const image = state.shapes.entities[imageId];

      if (image.type !== "image") {
        throw new Error(`Remove polygons from image failed: shape ${imageId} is not an image`);
      }

      const deletedPolygonsIds = polygonsAdapter
        .getSelectors()
        .selectAll(state.polygons)
        .filter(({ shapeId }) => shapeId === imageId)
        .map(({ id }) => id);

      image.polygons = [];

      polygonsAdapter.removeMany(state.polygons, deletedPolygonsIds);

      console.debug(`[workspaceSlice]: Removed all polygons from selected image`);
    },
    removeAllPhasesFromImage(state, action: PayloadAction<{ imageId: ShapeId }>) {
      const imageId = action.payload.imageId;
      const image = state.shapes.entities[imageId];

      if (image.type !== "image") {
        throw new Error(`Remove phases from image failed: shape ${imageId} is not an image`);
      }

      const deletedPhasesIds = phasesAdapter
        .getSelectors()
        .selectAll(state.phases)
        .filter(({ shapeId }) => shapeId === imageId)
        .map(({ id }) => id);

      phasesAdapter.removeMany(state.phases, deletedPhasesIds);

      console.debug(`[workspaceSlice]: Removed all phases from selected image`);
    },
    setImageEmbedding(state, action: PayloadAction<{ imageId: ShapeId; embedding: number[][][][] }>) {
      const { imageId, embedding } = action.payload;
      const image = state.shapes.entities[imageId];
      if (image.type != "image") throw new Error(`Shape ${imageId} is not an image`);

      console.debug("Your image got new embedding");
      image.embedding = embedding;
    },
    setImageSegmenting(state, action: PayloadAction<{ imageId: ShapeId; segmenting: boolean }>) {
      const { imageId, segmenting } = action.payload;
      const image = state.shapes.entities[imageId];
      if (image.type != "image") throw new Error(`Shape ${imageId} is not an image`);
      image.segmenting = segmenting;
    },
    addedShapeToSelection(
      state,
      action: PayloadAction<{
        layerId: LayerId;
        shapeId: ShapeId;
      }>,
    ) {
      const { layerId, shapeId } = action.payload;
      const layer = state.layers.entities[layerId];
      const shape = state.shapes.entities[shapeId];
      shape.selected = true;
      layer.shapes.selected.push(shapeId);
    },
    removedShapeFromSelection(
      state,
      action: PayloadAction<{
        layerId: LayerId;
        shapeId: ShapeId;
      }>,
    ) {
      const { layerId, shapeId } = action.payload;
      const layer = state.layers.entities[layerId];
      const shape = state.shapes.entities[shapeId];
      shape.selected = false;
      layer.shapes.selected = layer.shapes.selected.filter((id) => id !== shapeId);
    },
    toggledShapeSelection(
      state,
      action: PayloadAction<{
        layerId: LayerId;
        shapeId: ShapeId;
      }>,
    ) {
      const { layerId, shapeId } = action.payload;
      const shape = state.shapes.entities[shapeId];
      if (shape.selected) {
        workspaceSlice.caseReducers.removedShapeFromSelection(
          state,
          workspaceSlice.actions.removedShapeFromSelection({
            layerId,
            shapeId,
          }),
        );
      } else {
        workspaceSlice.caseReducers.addedShapeToSelection(
          state,
          workspaceSlice.actions.addedShapeToSelection({
            layerId,
            shapeId,
          }),
        );
      }
    },
    selectedShape(state, action: PayloadAction<{ layerId: LayerId; shapeId: ShapeId }>) {
      const { layerId, shapeId } = action.payload;
      workspaceSlice.caseReducers.unselectedAllShapes(
        state,
        workspaceSlice.actions.unselectedAllShapes({
          layerId,
        }),
      );
      workspaceSlice.caseReducers.addedShapeToSelection(
        state,
        workspaceSlice.actions.addedShapeToSelection({
          layerId,
          shapeId,
        }),
      );
    },
    unselectedShape(state, action: PayloadAction<{ layerId: LayerId; shapeId: ShapeId }>) {
      const { layerId, shapeId } = action.payload;
      const layer = state.layers.entities[layerId];
      const shape = state.shapes.entities[shapeId];
      shape.selected = false;
      layer.shapes.selected = layer.shapes.selected.filter((id) => id !== shapeId);
    },
    changedShape(
      state,
      action: PayloadAction<{
        shapeId: ShapeId;
        changes: Partial<Shape>;
      }>,
    ) {
      const { shapeId, changes } = action.payload;
      shapesAdapter.updateOne(state.shapes, { id: shapeId, changes });
    },
    removedShape(state, action: PayloadAction<{ shapeId: ShapeId }>) {
      const { shapeId } = action.payload;
      const shape = state.shapes.entities[shapeId];
      const layer = state.layers.entities[shape.layerId];
      layer.shapes.ids = layer.shapes.ids.filter((id) => id !== shapeId);
      layer.shapes.selected = layer.shapes.selected.filter((id) => id !== shapeId);
      shapesAdapter.removeOne(state.shapes, shapeId);
    },
    addedLayer: {
      reducer(state, action: PayloadAction<{ layer: Layer }>) {
        const { layer } = action.payload;
        state.layers = layersAdapter.addOne(state.layers, layer);
      },
      prepare({ layer }: { layer: Omit<Layer, "id"> }) {
        const id = `layer-${uuidv4()}`;
        const payload = { layer: { id, ...layer } };
        return { payload };
      },
    },
    addedNewLayer(state) {
      const layer: Omit<Layer, "id"> = {
        name: "New layer",
        visible: true,
        shapes: {
          ids: [],
          selected: [],
        },
      };
      workspaceSlice.caseReducers.addedLayer(state, workspaceSlice.actions.addedLayer({ layer }));
    },
    unselectedAllShapes(state, action: PayloadAction<{ layerId: LayerId }>) {
      const { layerId } = action.payload;
      const layer = state.layers.entities[layerId];
      for (const shapeId of layer.shapes.selected) {
        const shape = state.shapes.entities[shapeId];
        shape.selected = false;
      }
      layer.shapes.selected = [];
    },
    activatedLayer(state, action: PayloadAction<{ layerId: LayerId }>) {
      const { layerId } = action.payload;
      state.activeLayerId = layerId;
    },
    removedLayer(state, action: PayloadAction<{ layerId: LayerId }>) {
      const { layerId } = action.payload;
      const layer = state.layers.entities[layerId];

      for (const shapeId of layer.shapes.ids) {
        workspaceSlice.caseReducers.removedShape(
          state,
          workspaceSlice.actions.removedShape({
            shapeId,
          }),
        );
      }
      layersAdapter.removeOne(state.layers, layerId);
    },
  },
});

export const undoableWorkspaceReducer = undoable(workspaceSlice.reducer, {
  limit: 10,
  filter: (action) => {
    const ignoreActions: string[] = [
      workspaceSlice.actions.addedFile.type,
      workspaceSlice.actions.removedFile.type,
      workspaceSlice.actions.addedShapeToSelection.type,
      workspaceSlice.actions.removedShapeFromSelection.type,
      workspaceSlice.actions.toggledShapeSelection.type,
      workspaceSlice.actions.selectedShape.type,
      workspaceSlice.actions.unselectedShape.type,
    ];
    return !ignoreActions.includes(action.type);
  },
});

export const {
  addedFile,
  removedFile,
  addedImage,
  addedNewImage,
  addedPolygon,
  addedShape,
  updatedPolygon,
  removedPolygon,
  setImageEmbedding,
  setImageSegmenting,
  addedShapeToSelection,
  removedShapeFromSelection,
  toggledShapeSelection,
  selectedShape,
  unselectedShape,
  unselectedAllShapes,
  changedShape,
  removedShape,
  addedLayer,
  addedNewLayer,
  activatedLayer,
  removedLayer,
  arrangeShapes,
  removeAllPolygonsFromImage,
  polygonsAdded,
  phaseAdded,
  phaseVisibilityChanged,
  removeAllPhasesFromImage,
  phaseColoredChanged,
} = workspaceSlice.actions;

export default undoableWorkspaceReducer;
